<template>
	<div dark>
		<h3>Biertjes met form data</h3>

		<v-data-table  v-if="biertjes"
			:headers="headers"
			:items="biertjes"
			:items-per-page="10"
			class="elevation-1">
            
            <template v-slot:[`item.actions`]="{ item }">    
                <form-dialog type="upd" :formData="item" :headers="headers" class="mr-2"/>
                <form-dialog type="del" :formData="item" :headers="headers"/>
            </template>
        </v-data-table>

	</div>
</template>

<script>
//import axios from "axios";
import {  getBeers2 } from "@/api/bier.js";
import FormDialog from "@/components/drawers_dialogs_bars/FormDialog";

export default {
	name: "Bier",

    components: {
		FormDialog,
	},
    
	data: () => ({
		biertjes: [],
		//`id`, `naam`, `brouwer`, `type`, `gisting`, `perc`, `prijs`
		headers: [
			{   text: "Naam",         value: "naam",    fieldType:'text',    minChar:2,    maxChar:50, align: "start",       sortable: false },
			{   text: "Brouwer",      value: "brouwer", fieldType:'text',    minChar:2,    maxChar:50, },
			{   text: "Type",         value: "type",    fieldType:'text',    minChar:2,    maxChar:50, },
			{   text: "Gisting",      value: "gisting", fieldType:'text',    minChar:2,    maxChar:50, },
			{   text: "Percentage",   value: "perc",    fieldType:'decimal', minChar:2,    maxChar:50, },
			{   text: "Prijs",        value: "prijs",   fieldType:'decimal', minChar:2,    maxChar:50, },
            {   text: 'Actions',      value: 'actions', fieldType:'',        minChar:2,    maxChar:50, sortable: false }
		],
	}),

	created() {
        
        // getBeers().then((response) => {
        //     console.log("getBeers from Beer.vue. response:", response);
		// 	this.biertjes = response.data.data;
        //     return response.data.data
        // });

        let params = { id: 110, gisting:  'hoge met nof' }
        getBeers2(params.id, params.gisting).then((response) => {
            //console.log("getBeers2 from Beer.vue. data:", response.data.data);
            console.log("getBeers2 from Beer.vue. response:", response);
			this.biertjes = response.data.data;
            return response.data.data
        });
	},
    methods: {
		
        
	},
};
</script>