<template>
	<span class="text-center">
		<v-dialog v-model="dialogForm" width="500">
			<template v-slot:activator="{ on, attrs }">
				<v-icon v-if="type == 'upd'" v-bind="attrs" v-on="on" style="color:green" small >
					mdi-pencil
                </v-icon>
                <v-icon v-if="type == 'del'" v-bind="attrs" v-on="on" style="color:darkred" small>
					mdi-delete
                </v-icon>
			</template>
			<v-card class="mx-auto">
				<v-card-title class="text-h5 pb-4 primary">
					<h2 style="color: white">{{type}}</h2>
				</v-card-title>
				<v-card-text class="mt-6">
					<v-form>
                        <div v-for="(header, key, index) in headers" :key='index'>
                            <v-text-field
                                :label="header.text"
                                prepend-icon="mdi-account-circle"
                                v-model="header.value"
                            />
                        </div>
						<!-- <v-text-field
							label="Username"
							prepend-icon="mdi-account-circle"
							v-model="inaam"
						/>
						<v-text-field
							:type="showPassword ? 'text' : 'password'"
							label="Password"
							v-model="pw"
							prepend-icon="mdi-lock"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showPassword = !showPassword"
						/> -->
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="info" >Login</v-btn> <!-- @click="handleLogin" -->
					<v-spacer></v-spacer>
					<v-btn color="message" >clear</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</span>
</template>

<script>
//import axios from "axios";

export default {
	//name: "dialogForm",

    props: ['type', 'formData', 'headers'],

	data() {
		return {
			dialogForm: false,
			showPassword: false,

			inaam: "cstegeman",
			pw: "geheim",

			info_login: null,
		};
	},

	mounted: function () {
        // console.log("this.type = ", this.type);
		// console.log("this.formData = ", this.formData);
        // console.log("this.headers = ", this.headers);
	},
	methods: {
		editItem (item) {
            // this.editedIndex = this.desserts.indexOf(item)
            // this.editedItem = Object.assign({}, item)
            // this.dialog = true
            //console.log("item = ", item)
            this.$root.$emit("showSnackbar", {
                text: "Edit biertje: " + item.naam,
                type: "warning",
                time: 5000,
            });
        },

        deleteItem (item) {
            //this.editedIndex = this.desserts.indexOf(item)
            //this.editedItem = Object.assign({}, item)
            //this.dialogDelete = true
             this.$root.$emit("showSnackbar", {
                text: "Edit biertje: "+item.naam,
                type: "warning",
                time: 5000,
            });
        },

        deleteItemConfirm () {
            // this.desserts.splice(this.editedIndex, 1)
            // this.closeDelete()
            
        },

        close () {
            // this.dialog = false
            // this.$nextTick(() => {
            //     this.editedItem = Object.assign({}, this.defaultItem)
            //     this.editedIndex = -1
            // })
             
        },

        closeDelete () {
            // this.dialogDelete = false
            // this.$nextTick(() => {
            //     this.editedItem = Object.assign({}, this.defaultItem)
            //     this.editedIndex = -1
            // })
        },

        save () {
            if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
                this.desserts.push(this.editedItem)
            }
            this.close()
        },
	},
};
</script>
